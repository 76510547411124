import patientAccountService from "../../../core/services/patientAcountService";
import { 
    ConfirmExchangeEmailRequest,
    SendSMSConfirmEmailRequest,
    EmptyRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const ConfirmExchangeEmail = async (obj) => {
    try {
        const req = new ConfirmExchangeEmailRequest();
        req.setPhone(obj.phone);
        req.setCode(obj.code);

        const res = await patientAccountService.confirmExchangeEmail(req);

        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SendSMSConfirmEmail = async (phone) => {
    try {
        const req = new SendSMSConfirmEmailRequest();
        req.setPhone(phone);

        const res = await patientAccountService.sendSMSConfirmEmail(req);

        console.log(res);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const VerifyToken = async () => {
    try {
        const req = new EmptyRequest();

        const res = await patientAccountService.verifyToken(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

export {
    ConfirmExchangeEmail,
    SendSMSConfirmEmail,
    VerifyToken,
}