<template>
    <div class="form">
        <div class="header">
            <h1>Confirmação por SMS</h1>
        </div>

        <div class="limit">
            <p class="text-sendsms mt-16">Digite o código que recebeu no celular para confirmar a atualização do seu novo e-mail {{email}}.</p>
            <p class="text-code">Código enviado para {{ phone }}</p>

            <form class="sms-container">
                <div class="sms-text">
                    <InputMask v-model="code" class="input-code std-normal" mask="9 9 9 9 9 9"
                        placeholder="X X X X X X" autofocus />
                </div>
                <div class="flex justify-content-center" v-if="error">
                    <small class="p-invalid">
                        <i class="pi pi-times-circle p-ml-1" />Ops... confira o código de
                        confirmação.
                    </small>
                </div>
                <div class="sms-codetext p-mt-5">
                    <span class="ask-notnumber color-primary" v-if="count < 0" @click="reSend">Reenviar</span>
                    <span class="time-code" v-else>Seu código expira em ( {{ count }} )</span>
                </div>

                <div class="flex w-full justify-content-center mt-2">
                    <Button :disabled="!buttonActive" class="btn-submit" @click="submit">
                        <span class="p-button-label">Confirmar</span>
                        <i class="pi pi-spin pi-spinner" v-if="loading" />
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { ConfirmExchangeEmail, SendSMSConfirmEmail } from "./actions"

let router = useRouter();
const email = ref("");
const phone = ref("");
const code = ref("");
const count = ref(60);
const error = ref(false);
const loading = ref(false);
const buttonActive = computed(() => {
    if (sizeNumbersCode(code.value) === 6) {
        return true
    }

    return false;
})

const sizeNumbersCode = (code) => {
    const listNumbers = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9' ];
    let numbers = 0;

    for (let i = 0; i < 11; i++) {
        if (listNumbers.find(item => code[i] === item)) {
            numbers = numbers + 1;
        }
    }
    return numbers;
}

const submit = async () => {
    loading.value = true;
    const key = code.value.split(' ').join('');
    const phone = localStorage.getItem("phone").split(" ").join("");
    console.log('submit:', key)
    if(key.length === 6 && phone){
        const res = await ConfirmExchangeEmail({
            phone: phone,
            code: key,
        });
        console.log(res);
        if(res.success){
            router.push("/account/email/confirmation");
        }else{
            error.value = true;
        }
    }
    loading.value = false;
};

const reSend = () => {
    const phone = localStorage.getItem("phone").split(" ").join("");
    SendSMSConfirmEmail(phone);
    count.value = 60;
    startTime();
}

const startTime = () => {
    const myTimer = setInterval(function () {
        if(count.value >= 0){
            count.value = count.value - 1 ;
        }else{
            clearInterval(myTimer);
        }
    }, 1000);
}

onMounted(async () => {
    phone.value = localStorage.getItem('phone') || ''
    email.value = localStorage.getItem('email') || ''
    startTime();
})
</script>


<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    height: max-content;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header {
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;

        h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }

    .limit {
        width: 100% !important;
        padding: 0 32px;

        .text-sendsms {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            max-width: 40ch;
            margin: 0 auto;
        }

        .text-code {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #828282;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
        }

        .ask-notnumber {
            font-size: 14px;
            line-height: 22px;
            font-family: Roboto, sans-serif;
            font-weight: 400;
            color: #2F80ED;
            text-align: center;
            margin: 0 auto;
            /* transform: translateY(-2px); */
            cursor: pointer;
        }
        .color-primary{
            color: #FF6A33 !important;
            letter-spacing: .3px;
        }

        .sms-container {
            width: max-content;
            margin: 0 auto;
            margin-top: 6px;
            text-align: center;
            .input-code{
                width: max-content;
                font-size: 18px;
                padding: 0;
                height: 55px;
                letter-spacing: 5px;
                font-weight: 600;
            }
        }

        .sms-codetext {
            margin-top: 16px;

            .time-code {
                font-size: 14px;
                line-height: 22px;
                font-family: Roboto, sans-serif;
                font-weight: 400;
                color: #828282;
            }
        }
        .btn-submit{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            min-width: 230px;
            height: 42px !important;
        }
    }
}

@media (max-width: 580px) {
    .form {
        width: 100%;
    }
}

:deep() {
    .p-button{
        margin-top: 10px;
        padding: 20px 80px;
        width: 100%;
    }
    .p-inputmask {
        
        text-align: center;
        letter-spacing: 3px;
        font-size: 20px;
    }
}
</style>